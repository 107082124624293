import React from "react";

const Paso = ({ titulo, imagenSrc, descripcion }) => {
  return (
    <div className="flex">
      <div className="flex-none w-56 relative">
        <img
          src={imagenSrc}
          alt="{descripcion}"
          className="absolute inset-0 w-40 h-40 mt-5 object-cover rounded-lg"
          loading="lazy"
        />
      </div>
      <div className="flex-auto p-6">
        <div className="flex items-baseline mt-4 mb-6 pb-6 border-b border-slate-200">
          <div className="w-full flex-none mt-2 order-1 text-3xl font-bold text-primary">
            {titulo}
          </div>
        </div>
        <div className="flex space-x-4 mb-5 text-sm font-medium">
          <div className="flex-none mt-2 order-1 text-lg font-bold text-primary2">
            <h2>{descripcion}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return <div className="flex flex-row"></div>;
};
export default Footer;
