import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Bienvenida from "./components/bienvenida";
import Footer from "./components/footer";
import Formulario from "./components/formulario";
const App = () => {
  return (
    <div className="min-h-screen">
      {/* Sección de Bienvenida */}
      <Bienvenida />

      <Formulario />

      <Footer />
    </div>
  );
};

export default App;
