import React, { useEffect, useState } from "react";

const InputText = ({ label, field, placeholder, span = 2 }) => {
  return (
    <div className={"lg:col-span-" + span + " my-4 w-100  lg:mx-10"}>
      <label
        htmlFor={field}
        className="block text-lg  lg:text-xl font-bold leading-6 text-primary"
      >
        {label}
      </label>
      <div className="mt-2">
        <div className="flex ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <input
            type="text"
            name={field}
            id={field}
            autoComplete={field}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  );
};

const Formulario = () => {
  // Estados para almacenar los valores de los inputs

  const [questions, setQuestions] = useState({ steps: [] });
  const getData = () => {
    fetch("questions.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setQuestions(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault(); // Previene el comportamiento por defecto del formulario

    const json_data = getJsonfromInputs();

    console.log(json_data);
  };

  // Validación de los campos
  // por cada campo en questions, validar que el campo tenga un valor y
  // guardarlo en un json con el valor del campo
  // si todos los campos tienen valor, enviar el json
  const getJsonfromInputs = () => {
    let json_data = {};
    questions.steps.map((step) => {
      step.questions.map((question) => {
        let value = document.getElementById(question.name).value;
        if (question.required && value === "") {
          alert("campos" + question.name + "es requerido");
          return null;
        }
        json_data[question.name] = value;
        return null;
      });
      return null;
    });
    return json_data;
  };

  return (
    <div className="flex flex-col lg:flex-row p-8 bg-white">
      <div className="basis-full lg:basis-1/3">
        <h2 className="text-3xl  text-primary font-bold mb-4">Registrate</h2>
        <p className="pr-10">
          Completa la informacion para que no te pierdas ningun oferta laboral
        </p>
      </div>
      <div className="ml-2 lg:ml-10 basis-full lg:basis-2/3">
        <form>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12 grid grid-cols-1 lg:grid-cols-2">
              {questions.steps.map((step, stepIndex) => (
                <React.Fragment key={stepIndex}>
                  <h2 className="text-2xl font-bold leading-7 text-gray-900 lg:pb-4 lg:pt-10 lg:col-span-2">
                    {step.description}
                  </h2>
                  {step.questions.map((question, questionIndex) => (
                    <InputText
                      key={questionIndex}
                      label={question.field}
                      field={question.name}
                      placeholder={question.placeholder}
                      span={question.col || 2}
                    />
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="mt-6 flex items-center gap-x-6">
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-primary block flex-1 text-xl py-10 w-100 lg:mx-10 py-2 text-lg font-bold text-white hover:bg-primary2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Formulario;
