import React from "react";

const Bienvenida = () => {
  return (
    <div>
      <div className="flex flex-col lg:flex-row bg-primary px-4 pt-4 pb-5 lg:pt-10 lg:px-20 lg:pb-10 lg:pb-10 2xl:px-50 text-white ">
        <div className="basis-full lg:basis-1/2 items-center justify-center px-10 pb-10 ">
          <div>
            <img
              src="/images/logo.png"
              alt="Nurse"
              className="w-60 h-60 object-cover rounded-lg"
              loading="lazy"
            />
            <p className="text-3xl font-bold ">
              Sé parte de la<span className="text-secondary"> revolución</span>{" "}
              en el
              <span className="text-secondary"> reclutamiento</span> de
              <span className="text-secondary"> enfermer@s</span>
            </p>
          </div>
        </div>
        <div className="basis-full lg:basis-1/2 items-center justify-center">
          <div className="bg-secondary mx-8 px-8 py-10 text-4xl text-black rounded-lg">
            <div className="font-bold ">
              ¿Quieres Ser el primero en enterarte de NurseMatch?
            </div>
            <div className="font-bold text-3xl">¡No te quedes sin tu cupo!</div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b from-primary to-white py-10"></div>
    </div>
  );
};

export default Bienvenida;
